import { AUTH_USER, ERROR_AUTH, AUTH_LOGIN } from '../actions/types';
import isEmpty from '../validation/is-empty'
let initialState = {
    isAuthenticated: false,
    User: {},
    successMessage: null,
    error: null
}

export default function (state = initialState, action: any) {
    switch (action.type) {
        case AUTH_USER:
            return {
                ...state,
                successMessage: action.payload
            }
        case ERROR_AUTH:
            return {
                ...state,
                error: action.payload
            }

        case AUTH_LOGIN:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                User: action.payload,
            }
        default:
            return state;
    }
}
