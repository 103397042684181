import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import Axios from 'axios';
import keys from '../../keys';
import TagManager from 'react-gtm-module';
import { Alert } from 'react-bootstrap';

const tagManagerArgs = {
    gtmId: 'AW-10925220607',
    events: {
        conversion: 'conversion'
    }
}

TagManager.initialize(tagManagerArgs);

const Payment = (props) => {

    const selectEmail = useSelector((state) => state.searched.search);

    const [elements, setElements] = useState(
        <div className="text-center mt-5">
            <h3>Please wait redirecting to payment gateway...</h3>
            <Spinner animation="grow" variant="info" />
        </div>
    );

    useEffect(() => {
        if (!selectEmail) props.history.push('/');
    }, [props.history, selectEmail]);

    useEffect(() => {

        const script = document.createElement('script');

        script.src = 'https://checkout.razorpay.com/v1/checkout.js';

        script.async = true;

        document.body.appendChild(script);

        const plan = props.match.params.plan.split("*")

        let student = false;

        let premium = false;

        if (plan[1] === "student") {
            student = true
        } else if (plan[1] === "premium") {
            premium = true
        }
        
        Axios.post(`${keys.url}/payment/subscription`, { plan: plan[0], selectEmail, student, premium })
            .then(res => {
                const options = {
                    "key": res.data.keyId,
                    "subscription_id": res.data.order.id,
                    "name": "Code.in",
                    "description": res.data.order.notes.plan,
                    "image": "https://code.in/images/logo-gif.gif",
                    "handler": (response) => {
                        Axios.post(`${keys.url}/mail`, { mail: selectEmail, plan: props.match.params.plan, paymentDetails: response, paymentId: res.data.paymentId, student, premium })
                            .then(res => {
                                //payement
                                if (res.data.purchased) setElements(<div className="text-center mt-5">
                                    <h3 >Transaction Successful for {selectEmail}@code.in</h3>
                                    <p>Redirecting to dashboard...</p>
                                    <Spinner animation="grow" variant="primary" />
                                    {setTimeout(() => {
                                        props.history.push('/dashboard')
                                    }, 3000)}
                                </div>)
                                else setElements(<div className="text-center mt-5">
                                    <h3 >Transaction was not successful for {selectEmail}@code.in</h3>
                                    <p>Redirecting to home page...</p>
                                    <Spinner animation="grow" variant="primary" />
                                    {setTimeout(() => {
                                        props.history.push('/')
                                    }, 3000)}
                                </div>
                                )
                            })
                    },
                    "prefill": {
                        "name": res.data.order.notes.name,
                        "email": res.data.order.notes.email
                    },
                    "notes": {

                    },
                    "theme": {
                    }
                }
                const rzp1 = new window.Razorpay(options);
                rzp1.open();
                return
            })
            .catch(err => {
                setElements(<Alert className='text-center' color='danger'>{err.response?.data?.message}</Alert>)
            })
    }, [props.history, props.match.params.plan, selectEmail]);

    return (
        <div>
            {elements}
        </div>
    )
}

export default Payment;