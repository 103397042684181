import React, { Component } from 'react';
import axios from 'axios';

import keys from '../../keys';
import { GridLoad } from '../../components/Loading/Loading';

interface IGenerateTokenProps { }
interface IGenerateTokenState {
    email: string
    loading: boolean
    error: string
    successMessage: string
}

class GenerateToken extends Component<IGenerateTokenProps, IGenerateTokenState> {
    
    constructor(props: any) {
        super(props);
        this.state = {
            email: "",
            loading: false,
            error: "",
            successMessage: ""
        }
    }

    submitFrom = (e: any) => {
        
        e.preventDefault()

        const data = {
            email: this.state.email
        }

        this.setState({
            loading: true
        });

        axios.post(`${keys.url}/auth/verifyemail`, data)
            .then(res => {
                
                this.setState({
                    loading: false,
                    successMessage: res.data
                })
            })
            .catch(err => {
                console.log(err.response)
                this.setState({
                    loading: false,
                    error: "something went wrong, please try again later"
                })
            })

    }

    onChange = (e: any) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }

    resetMessages = () => {
        this.setState({
            error: "",
            successMessage: ""
        })
    }

    render() {
        
        const { loading, successMessage, error } = this.state;

        return (
            <div className="container">
                {successMessage && <div className="alert alert-success text-center" role="alert">{successMessage}</div>}
                {error && <div className="alert alert-danger text-center" role="alert">{error}</div>}
                <div className='card m-auto width-400px'>
                    {!loading
                        ? <form className="padding-25px" onSubmit={this.submitFrom}>
                            <div className="form-group">
                                <h3 className="text-center">Enter Email</h3>
                                <input type="email" className="form-control" onClick={this.resetMessages} name="email" required placeholder="Email" onChange={this.onChange} />
                            </div>
                            <div className="text-center">
                                <button className="btn btn-success" type="submit">Resend</button>
                            </div>
                        </form>
                        : <GridLoad />}
                </div>
            </div>
        );
    }
}

export default GenerateToken;