import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { resetMessages, authLogin } from '../../actions/authAction';

interface ILoginProps extends RouteComponentProps<any> {
    isAuthenticated: Boolean
    resetMessages: () => void
    authLogin: (user: Object) => void
    error: { data: String }
}

interface ILoginState {
    email: String
    password: String
    sucMessage: String
    errorMessage: String
}

class Login extends Component<ILoginProps, ILoginState> {
    state = {
        email: "",
        password: "",
        sucMessage: "",
        errorMessage: ""
    }
    componentWillMount() {
        if (this.props.isAuthenticated) {
            this.props.history.push('/')
        }
        this.props.resetMessages()
        if (this.props.match) {
            if (this.props.match.params.message !== undefined) {
                this.setState({
                    sucMessage: this.props.match.params.message,
                    errorMessage: ""
                })
            }
        }

    }
    
    componentWillReceiveProps(nextProps: any) {
        if (nextProps.isAuthenticated) {
            const lastUrl = localStorage.getItem('lastUrl');
            if (lastUrl) {
                this.props.history.push(lastUrl);
            } else {
                this.props.history.push('/');
            }
        }
    }

    onChange = (e: any) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        });
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        this.props.authLogin({
            email: this.state.email,
            password: this.state.password
        })
    }

    render() {
        const { error } = this.props;
        const { sucMessage } = this.state
        return (
            <div className="mt-5">
                <div className="card m-auto w-login p-3">
                    {!sucMessage ? null : <div className="alert alert-success alert-dismissible text-center" role="alert">{sucMessage}</div>}
                    {!error ? null : <div className="alert alert-danger text-center" role="alert">{JSON.stringify(error.data)}</div>}
                    <form className="padding-25px" onSubmit={this.onSubmit}>
                        <h1 className="text-center">Login</h1>
                        <div className="form-group text-left">
                            <label >Email address</label>
                            <input type="email" className="form-control" onClick={this.props.resetMessages} onChange={this.onChange} id="exampleInputEmail1" name="email" aria-describedby="emailHelp" placeholder="Enter email" />
                        </div>
                        <div className="form-group text-left">
                            <label >Password</label>
                            <input type="password" className="form-control" onClick={this.props.resetMessages} onChange={this.onChange} name="password" id="exampleInputPassword1" placeholder="Password" />
                        </div>
                        <p><Link to="/forgot-password">Forgot Password?</Link></p>
                        <div className="text-center">
                            <button className="btn btn-success" type="submit">Login</button>
                        </div>
                        <br />
                        <p className="text-center">Don't have an account? <Link className="link" to="/register">Register</Link></p>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    error: state.auth.error,
    successMessage: state.auth.successMessage,
    User: state.auth.User,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps, { resetMessages, authLogin })(Login);