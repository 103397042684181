//global imports
import React, { Component, Suspense } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import jwt_decode from 'jwt-decode';
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module'

//local imports
// import logo from './logo.svg';
import './App.css';
import NavBar from './components/Layout/Navbar';
import Home from './components/Home/Home';
import Pricing from './components/Pricing/Pricing';
import Plans from './components/Plans/Plans';
import Payment from './components/Payment/Payment';
// import Register from './components/Auth/Register';
import RegisterLocal from './components/Auth/LocalRegister';
import VerifyEmail from './components/Auth/VerifyEmail';
import Dashboard from './components/Dashboard/Dashboard';
import Challenge from './components/Challenge/Challenge';
import store from './store';
// import Login from './components/Auth/Login';
import LoginLocal from './components/Auth/LoginLocal';
import setAuthToken from './utils/setAuthToken';
import { setCurrentUser, logoutUser } from './actions/authAction';
import GenerateToken from './components/Auth/GenerateToken';
import ForgotPassword from './components/Auth/ForgotPassword';
import ResetPassword from './components/Auth/ResetPassword';
import Oauth from './components/Auth/Oauth';
import Footer from './components/Layout/Footer';
// import AdminDashboard from './components/Admin/Dashboard';

//HOC
import PrivateRoute from './components/Hoc/PrivateRoute';
import AdminRoutes from './components/Hoc/AdminRoutes'


const advancedMatching = { em: 'some@email.com' };
const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};
ReactPixel.init('344454483548546', advancedMatching, options);
ReactPixel.pageView();

const tagManagerArgs = {
  gtmId: 'AW-10925220607'
}

TagManager.initialize(tagManagerArgs)


//lazy components
const AdminDashboard = React.lazy(() => import('./components/Admin/Dashboard'));

// Chcek token
if (localStorage.jwtToken) {

  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);

  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);

  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expire token
  const currentTime = Date.now() / 1000;

  if (decoded.exp < currentTime) {

    // Logout User
    store.dispatch(logoutUser());
    //TODO: Clear current profile
    // store.dispatch(clearCurrentProfile);
    // Redirect to login
    window.location.href = "/login";
  }
}

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <header className="App-header">
              <NavBar />
            </header>
            <div className="margin-top-bottom-50px">
              
              {/* Common Routes */}
              <Route exact path="/" component={Home} />
              <Route exact path="/pricing" component={Pricing} />
              <Route exact path="/plans" component={Plans} />
              <Route exact path="/register" component={RegisterLocal} />
              <Route exact path="/register/local" component={RegisterLocal} />
              <Route path="/oauth/:token/success/:success" component={Oauth} />
              <Route exact path="/login" component={LoginLocal} />
              <Route exact path="/login/local" component={LoginLocal} />
              <Route exact path="/login/local/:message" component={LoginLocal} />
              <Route exact path="/verifyemail/:id" component={VerifyEmail} />
              <Route exact path="/generate-verification-email" component={GenerateToken} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route exact path="/resetPassword/:id" component={ResetPassword} />
              {/* Common Routes */}

              {/* Private Routes */}
              <PrivateRoute exact path="/buy/:plan" component={Payment} />
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute exact path="/dashboard/challenge/:id" component={Challenge} />
              {/* Private Routes */}

              {/* Admin Route */}
              <Suspense fallback={<div>Loading...</div>}>
                <AdminRoutes path="/admin" component={AdminDashboard} />
              </Suspense>
              {/* Admin Routes */}

            </div>
            <Footer />
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
