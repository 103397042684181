  
import { SEARCH, SEARCH_ALERT } from '../actions/types';

let initialState = {
    search: "",
    alert: '',
}

export default function (state = initialState, action: any) {
    switch (action.type) {
        case SEARCH:
            return {
                ...state,
                search: action.payload,
            }
        case SEARCH_ALERT: 
            return {
                ...state,
                alert: action.payload
            }
        default:
            return state;
    }
}