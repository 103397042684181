import { ERROR_MESSAGE } from '../actions/types';

let initialState = {
    error: ""
}

export default function (state = initialState, action: any) {
    switch (action.type) {
        case ERROR_MESSAGE:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state;
    }
}
