import { AUTH_USER, ERROR_AUTH, AUTH_LOGIN } from './types';
import axios from 'axios'
import jwt_decode from 'jwt-decode';
import keys from '../keys';

import setAuthToken from '../utils/setAuthToken';

export const registerLocal = (data: any) => (dispatch: any) => {
    axios.post(`${keys.url}/auth/register`, data)
        .then(res => res)
        .then(data => {
            // dispatch({
            //     type: AUTH_USER,
            //     payload: data
            // })
            const decoded: any = jwt_decode(data.data.token)
            console.log(decoded)
            // if (decoded.verified) {
                console.log(decoded)
                setAuthToken(data.data.token)
                localStorage.setItem('jwtToken', data.data.token);
                dispatch(setCurrentUser(decoded))
        })
        .catch(err => {
            dispatch({
                type: ERROR_AUTH,
                payload: err.response
            })
        })
}

export const resetMessages = () => (dispatch: any) => {
    dispatch({
        type: AUTH_USER,
        payload: null
    })
    dispatch({
        type: ERROR_AUTH,
        payload: null
    })
}

export const authLogin = (data: any) => (dispatch: any) => {
    axios.post(`${keys.url}/auth/login`, data)
        .then((res) => res)
        .then((data) => {
            const decoded: any = jwt_decode(data.data.token)
            console.log(decoded)
            // if (decoded.verified) {
                console.log(decoded)
                setAuthToken(data.data.token)
                localStorage.setItem('jwtToken', data.data.token);
                dispatch(setCurrentUser(decoded))
            // } else {
                // dispatch({
                    // type: ERROR_AUTH,
                    // payload: { data: "Please verify your account first.", verify: true }
                // })
            // }

        })
        .catch(err => {
            dispatch({
                type: ERROR_AUTH,
                payload: err.response
            })
        })
}
// set logged in user 
export const setCurrentUser = (decoded: any) => {
    return {
        type: AUTH_LOGIN,
        payload: decoded
    }

}

export const oauthAction = (token: any) => (dispatch: any) => {
    // Set token to localstorage
    localStorage.setItem("jwtToken", token);
    // Set token to auth header
    setAuthToken(token);
    // Decode token to get user data
    const decoded = jwt_decode(token);
    // Set current user
    dispatch(setCurrentUser(decoded));
};


// Log user out
export const logoutUser = () => (dispatch: any) => {

    // Remove token from localStorage
    localStorage.removeItem('jwtToken');
    // Remove auth header to feature request
    setAuthToken(false);
    // Set current user to {} which will set isAuthenticated to false 
    dispatch(setCurrentUser({}))
    window.location.href = '/'
}