//global imports
import React from "react";
import { useHistory } from "react-router-dom";

const Process = (props: any) => {
  const history = useHistory();
  const proceed = () => {
    
    history.push('/plans');
  }
  return (
    <div className="Process">
      <br />
      <h3 className="text-center">
        Early bird! <strong>{props.userName}@code.in</strong> is available.
        <br /> Here's how to make it yours
      </h3>
      <br />
      <div>
        <div id="process" className="text-center">
          <div className="box">
            1<span className="box-content">Choose Email</span>
          </div>
          <div className="box">
            2<span className="box-content">Subscribe & secure</span>
          </div>
          <div className="box">
            3<span className="box-content">Take the challenge</span>
          </div>
          <div className="box">
            4<span className="box-content">Own your code.in email</span>
          </div>
        </div>
      </div>
      <div className="text-center mt-5">
        <span className="btn text-white btn-info" onClick={proceed}>
          Proceed
        </span>
      </div>
      <br />
    </div>
  );
};

export default Process;
