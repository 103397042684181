import React, { Component } from 'react';
import axios from 'axios';

import { GridLoad } from '../../components/Loading/Loading';

interface IFpProps {

}

interface IFpState {
    email: String
    loading: Boolean
    successMessage: any
    error: any
}

class ForgotPassword extends Component<IFpProps, IFpState> {
    constructor(props: any) {
        super(props);
        this.state = {
            email: "",
            loading: false,
            successMessage: null,
            error: null
        }
    }

    submitFrom = (e: any) => {

        e.preventDefault()

        const data = {
            email: this.state.email
        }

        this.setState({
            loading: true
        })

        axios.post('http://api.jscode.in/auth/forgotPassword', data)
            .then(res => {
                
                this.setState({
                    successMessage: res.data.message,
                    loading: false
                })
            })
            .catch(err => {
                console.log(err)
                this.setState({
                    error: "something went wrong, please try again later",
                    loading: false
                })
            })
        
    }

    onChange = (e: any) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }

    resetMessages = () => {
        this.setState({
            error: null,
            successMessage: null
        })
    }

    render() {
        const { loading, successMessage, error } = this.state;
        
        return (
            <div className="container">
                {successMessage && <div className="alert alert-success text-center" role="alert">{successMessage}</div>}
                {error && <div className="alert alert-danger text-center" role="alert">{JSON.stringify(error)}</div>}
                <div className='card m-auto width-400px'>
                    {!loading ? <form className="padding-25px" onSubmit={this.submitFrom}>
                        <div className="form-group">
                            <h3 className="text-center">Enter Email</h3>
                            <input type="email" className="form-control" onClick={this.resetMessages} name="email" required placeholder="Email" onChange={this.onChange} />
                        </div>
                        <div className="text-center">
                            <button className="btn btn-success" type="submit">Send Link</button>
                        </div>
                    </form>
                        : <GridLoad />}
                </div>
            </div>
        );
    }
}

export default ForgotPassword;