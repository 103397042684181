import React, { Component } from 'react';
import { connect } from 'react-redux';
import { oauthAction } from "../../actions/authAction";
import { RouteComponentProps } from 'react-router-dom';

interface IOAuthProps extends RouteComponentProps<any> {
    oauthAction: (token: string) => void
}

interface IOAuthState { }

class Oauth extends Component<IOAuthProps, IOAuthState> {
    state = {}

    componentDidMount() {
        console.log(this.props.match.params.token)
        console.log(this.props.match.params.success)
        this.props.oauthAction(`Bearer ${this.props.match.params.token}`)
    }

    componentWillReceiveProps(nextProps: any) {
        if (nextProps.auth.isAuthenticated) {
            this.props.history.push("/home/products");
        }
    }

    render() {
        return (
            <div className="Tcenter">
                <h3>Please Wait...</h3>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    auth: state.auth,
});

export default connect(
    mapStateToProps,
    { oauthAction }
)(Oauth);