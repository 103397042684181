  
import { MAIL } from '../actions/types';

let initialState = {
    mails: [],
}

export default function (state = initialState, action: any) {
    switch (action.type) {
        case MAIL:
            return {
                ...state,
                mails: action.payload,
            }
        default:
            return state;
    }
}