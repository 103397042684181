//global imports
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';

//local imports
import { BlueLogo } from "../../images";
import { logoutUser } from '../../actions/authAction';

interface INavBarProps {
    logoutUser: () => void
    isAuthenticated: Boolean
    User: any
}

interface INavBarState { }

class NavBar extends Component<INavBarProps, INavBarState> {

    onLogout = (e: any) => {
        e.preventDefault()
        this.props.logoutUser()
    }

    render() {
        const { isAuthenticated, User } = this.props
        const authLinks = (
            <ul className="ml-auto navbar-nav">
                {User.role === 'admin' &&
                    <li className="nav-item active">
                        <Link
                            className="nav-link"
                            to="/admin"
                        >
                            <span>Admin Dashboard</span>
                        </Link>
                    </li>
                }
                <li className="nav-item active">
                    <Link
                        className="nav-link"
                        to="/dashboard"
                    >
                        <span>{User.name}</span>
                    </Link>
                </li>
                <li className="nav-item active">
                    <span className="nav-link pointer" onClick={this.onLogout}>
                        <span>Logout</span>
                    </span>
                </li>
            </ul>
        );
        const guestLinks = (
            <ul className="ml-auto navbar-nav">
                <li className="nav-item active">
                    <a href="/#about" className="nav-link">
                        About
                </a>
                </li>
                <li className="nav-item active">
                    <Link to="/pricing" className="nav-link">
                        Pricing
                </Link>
                </li>
                <li className="nav-item active">
                    <Link className="nav-link" to="/login">
                        Login
                </Link>
                </li>
            </ul>
        );
        return (
            <nav className="navbar navbar-expand-lg shadow navbar-light bg-light">
                <Link className="ml-5 navbar-brand" to="/">
                    <img className="brand-logo" src={BlueLogo} alt="code.in" />
                </Link>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <>{isAuthenticated ? authLinks : guestLinks} </>
                </div>
            </nav>
        );
    }
}

const mapStateToProps = (state: any) => ({
    User: state.auth.User,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps, { logoutUser })(NavBar);
