import React from 'react';

const GrownigSpinner = (props: any) => {

    return <>
        {props.alert.success
            &&
            <div
                className="alert alert-success"
                role="alert"
            >
                {props.alert.success}
            </div>
        }
        {props.alert.error
            &&
            <div
                className="alert alert-danger"
                role="alert"
            >
                {props.alert.error}
            </div>
        }
    </>
}

export default GrownigSpinner;