import React, { Component } from 'react';
import axios from 'axios';
// import GenerateToken from './GenerateToken';
import { RouteComponentProps } from 'react-router-dom';
import keys from '../../keys';

interface IVerifyEmailProps extends RouteComponentProps<any> { }

interface IVerifyEmailState {
    error: {
        message: String
        type: String
    }
    successMessage: String
}

class VerifyEmail extends Component<IVerifyEmailProps, IVerifyEmailState> {
    state = {
        error: {
            message: "",
            type: ""
        },
        successMessage: ""
    }

    componentWillMount() {
        // this.props.verifyEmail(this.props.match.params.id)
        axios.get(`${keys.url}/auth/confirmation/${this.props.match.params.id}`)
            .then(res => {
                this.setState({
                    successMessage: res.data.message
                })
            })
            .catch(err => {
                console.log(err.response)
                this.setState({
                    error: err.response.data
                })
            })
    }

    render() {
        const { successMessage, error } = this.state
        return (
            <div className="item-center text-center container">
                {successMessage &&
                    <>
                        <h3>{successMessage}</h3>
                        <br />
                        <h4>Please wait redirecting to login...</h4>
                        {setTimeout(() => {
                            this.props.history.push('/login')
                        }, 3000)}
                    </>}
                    {!error && !successMessage ?
                    <>
                        <h3>Verifying your email please wait...</h3>
                    </>
                    : <>
                        
                    </>}
            </div>
        )
    }
}


export default VerifyEmail;