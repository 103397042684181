//global imports
import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";
import { PDFReader } from 'reactjs-pdf-reader';

//local imports
import keys from '../../keys';

const Challenge = (props) => {

    const [challenge, setChallenge] = useState({
        _id: "",
        algoChallenge: "",
        webChallenge: ""
    })

    const [alert, setAlert] = useState({
        success: "",
        error: ""
    })

    const [showToggle, setShowToggle] = useState(false);

    const [submission, setSubmission] = useState({
        webChallenge: "",
        algoChallenge: ""
    })

    useEffect(() => {
        Axios.get(`${keys.url}/profile`)
            .then(res => {
                const c = res.data.purchasedEmails.filter((c) => c._id === props.match.params.id)
                setChallenge(c[0].challenge[0])
                setSubmission(c[0].challengeSubmission ? c[0].challengeSubmission : {
                    webChallenge: "",
                    algoChallenge: ""
                })
            })
    }, [props.match.params.id]);

    const editorChange1 = (e) => {
        setSubmission({
            ...submission,
            algoChallenge: e
        })
    }

    const editorChange2 = (e) => {
        setSubmission({
            ...submission,
            webChallenge: e
        })
    }

    const onSubmit = () => {
        Axios.put(`${keys.url}/profile/submitchallenge/${props.match.params.id}`, submission)
            .then(res => {
                setAlert({
                    error: "",
                    success: res.data.message
                })
            })
            .catch(err => {
                setAlert({
                    error: "something went wrong, please try again later",
                    success: ""
                })
            })
    }

    return (
        <div className="row challenge">
            <div className="col-lg-6 bg-white">
                <div className="">
                    <div className="pdf-viewer">
                        {challenge.challenge
                            &&
                            <PDFReader
                                url={challenge.challenge}
                                showAllPage={true}
                                scale={1.2}
                            />
                        }
                    </div>

                </div>
            </div>
            <div className="col-lg-6">
                <div className="h-100 w-100">
                    <div
                        className={
                            showToggle
                                ? "d-inline pdf-reader"
                                : "d-none pdf-reader"
                        }>
                        <AceEditor
                            className={`w-100`}
                            mode="javascript"
                            theme="monokai"
                            value={submission.algoChallenge}
                            onChange={(e) => editorChange1(e)}
                            name="1"
                            fontSize={20}
                            editorProps={{ $blockScrolling: true }}
                        />
                    </div>
                    <div
                        className={
                            showToggle
                                ? "d-none pdf-reader"
                                : "d-inline pdf-reader"
                        }
                    >
                        <AceEditor
                            className={`w-100`}
                            mode="javascript"
                            theme="monokai"
                            value={submission.webChallenge}
                            onChange={(e) => editorChange2(e)}
                            name="2"
                            fontSize={20}
                            editorProps={{ $blockScrolling: true }}
                        />
                    </div>
                    <div className="text-center m-2">
                        <h4>{
                            showToggle
                                ? "Alog Challenge"
                                : "Web Challenge"
                        }</h4>
                        <div className="w-100 text-center mt-5">
                            {alert.error
                                && <small className="text-center text-red">
                                    {alert.error}
                                    <br />
                                    <br />
                                </small>}

                            {alert.success
                                && <small className="text-center text-success">
                                    {alert.success}
                                    <br />
                                    <br />
                                </small>}
                        </div>
                        
                        <button
                            className="btn btn-info"
                            onClick={() => setShowToggle(!showToggle)}>
                            {showToggle ? "Write Web Solution" : "Write Algo Solution"}
                        </button>

                        <button
                            onClick={onSubmit}
                            className="btn btn-success"
                        >
                            Submit Challenge
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Challenge;