import React, { Component } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';

import { registerLocal, resetMessages } from '../../actions/authAction';
import { BarLoad } from '../../components/Loading/Loading';

interface IRegisterProps extends RouteComponentProps<any> {
    isAuthenticated: Boolean
    resetMessages: () => void
    registerLocal: (data: any) => void
    successMessage: { data: { message: String } }
    error: { data: String }
}

interface IRegisterState { }


class Register extends Component<IRegisterProps, IRegisterState> {

    state = {
        name: "",
        lastName: "",
        email: "",
        password: "",
        password2: "",
        loading: false
    }

    componentWillMount() {

        if (this.props.isAuthenticated) {
            this.props.history.push('/secret')
        }
        this.props.resetMessages()
    }

    onChange = (e: any) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    onSubmit = (e: any) => {
        e.preventDefault()
        const data = {
            name: this.state.name,
            email: this.state.email,
            password: this.state.password,
            password2: this.state.password2
        }
        this.setState({
            loading: true
        })
        this.props.registerLocal(data)
    }

    componentWillReceiveProps(nextProps: any) {
        this.setState({
            loading: false
        })

        if (nextProps.isAuthenticated) {
            const backurl = localStorage.getItem("lastUrl")

            if(backurl){
                nextProps.history.push(backurl)
            }else{
                nextProps.history.push('/dashboard')
            }
        }
    }

    render() {

        const { error, successMessage } = this.props;

        const { loading } = this.state;
        
        return (
            <div className="mt-4">
                <div className="card m-auto w-login p-3 positionInherit">
                    {loading && <BarLoad />}
                    {!successMessage ? null : <div className="alert alert-success alert-dismissible text-center" role="alert">{successMessage.data.message}<button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>}
                    {!error ? null : <div className="alert alert-danger text-center alert-dismissible" role="alert">{JSON.stringify(error.data)}<button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>}
                    <form className="padding-25px" onSubmit={this.onSubmit}>
                        <h2 className="text-center">Create an account</h2>
                        <div className="form-group">
                            <label >Name</label>
                            <input type="text" className="form-control" onClick={this.props.resetMessages} required name="name" aria-describedby="emailHelp" placeholder="Name" onChange={this.onChange} />
                        </div>
                        <div className="form-group">
                            <label >Email address</label>
                            <input type="email" className="form-control" onClick={this.props.resetMessages} required name="email" aria-describedby="emailHelp" placeholder="Email" onChange={this.onChange} />
                            <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                        </div>
                        <div className="form-group">
                            <label >Password</label>
                            <input type="password" className="form-control" onClick={this.props.resetMessages} name="password" required placeholder="Password" onChange={this.onChange} />
                        </div>
                        <div className="form-group">
                            <label >Confirm Password</label>
                            <input type="password" className="form-control" onClick={this.props.resetMessages} name="password2" required placeholder="Confirm Password" onChange={this.onChange} />
                        </div>
                        <div className="text-center">
                            <button className="btn btn-success" type="submit">Register</button>
                        </div>
                        <br />
                        <p className="text-center">Already have an account? <Link className="link" to="/login">Login</Link></p>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    error: state.auth.error,
    successMessage: state.auth.successMessage,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps, { registerLocal, resetMessages })(Register);