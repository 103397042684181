import React, { Component } from 'react';
import axios from 'axios';
import { RouteComponentProps } from 'react-router-dom';

import keys from '../../keys';
import { GridLoad } from '../../components/Loading/Loading';
import Login from './Login';

interface IResetPasswordState {
    loading: Boolean
    successMessage: String
    error: String
    password: String
    password2: String
    email: String
    token: String
    inputShow: Boolean
    loginShow: Boolean
}

interface IResetPasswordProps extends RouteComponentProps<any> {

}

class ResetPassword extends Component<IResetPasswordProps, IResetPasswordState> {
    state = {
        loading: true,
        successMessage: "",
        error: "",
        password: "",
        password2: "",
        email: "",
        token: "",
        inputShow: false,
        loginShow: false
    }
    onChange = (e: any) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }
    resetMessages = () => {
        this.setState({
            ...this.state,
            error: "",
            successMessage: ""
        })
    }

    componentDidMount() {
        this.checkToken()
    }

    checkToken = () => {
        axios.get(`${keys.url}/auth/resetPassword/${this.props.match.params.id}`)
            .then(res => {
                
                this.setState({
                    loading: false,
                    successMessage: res.data.message,
                    email: res.data.Email,
                    token: res.data.token,
                    inputShow: true
                })
            })
            .catch(err => {
                console.log(err.response)
                this.setState({
                    loading: false,
                    error: err.response.data.msg
                })
            })
    }

    onSubmit = (e: any) => {
        e.preventDefault()
        this.setState({
            loading: true
        })
        if (this.state.password === this.state.password2) {
            const data = {
                email: this.state.email,
                password: this.state.password,
                password2: this.state.password2,
                token: this.state.token
            }
            axios.put(`${keys.url}/auth/updatePassword`, data)
                .then(res => {
                    
                    this.setState({
                        successMessage: res.data.message,
                        loading: false,
                        inputShow: false,
                        loginShow: true
                    })
                })
                .catch(err => {
                    console.log(err.response)
                    this.setState({
                        error: "something went wrong, please try again later"
                    })
                })
        } else {
            this.setState({
                ...this.state,
                error: "Password and Confirm Password should match.",
                loading: false,
            })
        }

    }

    render() {
        const { loading, successMessage, error, inputShow, loginShow } = this.state
        return (
            <div className="container">
                {successMessage && <div className="alert alert-success text-center" role="alert">{successMessage}</div>}
                {error && <div className="alert alert-danger text-center" role="alert">{error}</div>}
                <div className='card m-auto width-400px'>
                    {!loading ? <div>{loginShow && <Login />}{inputShow && <form className="padding-25px" onSubmit={this.onSubmit}>
                        <h3 className="text-center">Enter New Password</h3>
                        <div className="form-group">
                            <label>Password</label>
                            <input type="password" className="form-control" onClick={this.resetMessages} name="password" required placeholder="Password" onChange={this.onChange} />
                        </div>
                        <div className="form-group">
                            <label>Confirm Password</label>
                            <input type="password" className="form-control" onClick={this.resetMessages} name="password2" required placeholder="Confirm Password" onChange={this.onChange} />
                        </div>
                        <div className="text-center">
                            <button className="btn btn-success" type="submit">Reset</button>
                        </div>
                    </form>}</div>
                        : <GridLoad />}
                </div>
            </div>
        );
    }
}

export default ResetPassword;