import { combineReducers } from 'redux';

import searchReducer from './searchReducer';
import authReducer from './authReducer';
import errorReducer from './errorReducer'
import mailReducer from './mailReducer';
export default combineReducers({
    searched: searchReducer,
    auth: authReducer,
    error: errorReducer,
    mails: mailReducer
})