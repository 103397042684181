import React from 'react';
import Modal from 'react-bootstrap/Modal';

const Help = (props: any) => {
    return (
        <Modal show={props.show} onHide={props.close}>
            <Modal.Header closeButton>
                <Modal.Title>
                    We're here to help!
                    </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>Please contact us on <a className="text-info" href="mailto:team@code.in">team@code.in</a></h5>
            </Modal.Body>
        </Modal>
    )
}

export default Help;
