import React from "react";
import { Route, Redirect } from "react-router-dom";

import { connect } from "react-redux";
import PropTypes from "prop-types";

const PrivateRoute = ({ component: Component, auth, ...rest }: any, props: any) => {
    return <Route
        {...rest}
        render={props =>
            auth.isAuthenticated === true ? (
                <Component {...props} />
            ) : (
                    <>
                        {localStorage.setItem('lastUrl', window.location.pathname)}
                        <Redirect to="/login" />
                    </>
                )
        }
    />
};

PrivateRoute.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = (state: any) => ({
    auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);