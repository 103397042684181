let envVariables = {
    url: ""
}
if (process.env.NODE_ENV === "development") {
    envVariables = {
        url: "http://localhost:3001"
    }
} else if (process.env.NODE_ENV === "production") {
    envVariables = {
        url: "https://api.email.code.in"
    }
}

export default envVariables
