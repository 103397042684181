import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

import keys from '../../keys';
import { resetMessages, authLogin } from '../../actions/authAction';

interface ILoginProps extends RouteComponentProps<any> {
    isAuthenticated: boolean
    resetMessages: () => void
}

interface ILoginState { }


class Login extends Component<ILoginProps, ILoginState> {
    constructor(props: any) {
        super(props);
        this.state = {

        }
    }

    componentWillMount() {
        if (this.props.isAuthenticated) {
            this.props.history.push('/secret')
        }
        this.props.resetMessages()
    }

    componentWillReceiveProps(nextProps: any) {
        console.log(nextProps.isAuthenticated)
        if (nextProps.isAuthenticated) {
            this.props.history.push('/secret')
        }
    }

    render() {
        return (
            <div className="card m-auto w-login positionInherit">
                <div className="padding-25px text-center positionInherit">
                    <h1>Login</h1>
                    <br />
                    <div className="box">
                        <a className="btn btn-si btn-google" href={`${keys.url}/auth/google`}>Sign in with Google</a>
                        <br />
                        <br />
                        <a className="btn btn-si btn-facebook" href={`${keys.url}/auth/facebook`}>Sign in with Facebook </a>
                        <br />
                        <br />
                        <a className="btn btn-si btn-github" href={`${keys.url}/auth/github`}>Sign in with GitHub</a>
                        <br />
                        <br />
                        <a className="btn btn-si btn-linkedin" href={`${keys.url}/auth/linkedin`}>Sign in with LinkedIn </a>
                    </div>
                    <br />
                    <p><Link className="Link" to="/login/local">Click here</Link> to login with email and password.</p>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: any) => ({
    error: state.auth.error,
    successMessage: state.auth.successMessage,
    User: state.auth.User,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(
    mapStateToProps,
    { resetMessages, authLogin }
)(withRouter(Login));