import React, { useState } from 'react';
import { css } from '@emotion/core';
// First way to import
import { HashLoader, GridLoader, BarLoader, ClipLoader, PulseLoader, RotateLoader, PropagateLoader } from 'react-spinners';

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
    display: block;
    margin: 0 auto;
    color: blue;
    margin-top: 25px;
    margin-bottom: 25px;
`;

const HashLoad = () => {
    const [loading] = useState(true)
    return (
        <div className='sweet-loading'>
            <HashLoader
                css={override}
                sizeUnit="px"
                size={50}
                color={'#00397a'}
                loading={loading}
            />
        </div>
    )
}

const PulseLoad = () => {
    const [loading] = useState(true)
    return (
        <div className='sweet-loading'>
            <PulseLoader
                css={override}
                sizeUnit="px"
                size={50}
                color={'#00397a'}
                loading={loading}
            />
        </div>
    )
}

const ClipLoad = () => {
    const [loading] = useState(true)
    return (
        <div className='sweet-loading'>
            <ClipLoader
                css={override}
                sizeUnit="px"
                size={50}
                color={'#00397a'}
                loading={loading}
            />
        </div>
    )
}

const BarLoad = () => {
    const [loading] = useState(true)
    return (
        <div className='sweet-loading'>
            <BarLoader
                css={override}
                sizeUnit="px"
                size={50}
                color={'#00397a'}
                loading={loading}
            />
        </div>
    )
}

const GridLoad = () => {
    const [loading] = useState(true)
    return (
        <div className='sweet-loading'>
            <GridLoader
                css={override}
                sizeUnit="px"
                size={50}
                color={'#00397a'}
                loading={loading}
            />
        </div>
    )
}

const RotateLoad = () => {
    const [loading] = useState(true)
    return (
        <div className='sweet-loading'>
            <RotateLoader
                css={override}
                sizeUnit="px"
                size={50}
                color={'#00397a'}
                loading={loading}
            />
        </div>
    )
}

const PropagateLoad = () => {
    const [loading] = useState(true)
    return (
        <div className='sweet-loading'>
            <PropagateLoader
                css={override}
                sizeUnit="px"
                size={50}
                color={'#00397a'}
                loading={loading}
            />
        </div>
    )
}

export {
    HashLoad,
    PulseLoad,
    ClipLoad,
    BarLoad,
    GridLoad,
    RotateLoad,
    PropagateLoad
}