//global imports
import React, { useEffect, useState } from 'react';

//local imports
import keys from '../../keys';
import Axios from 'axios';
import Alert from '../Alert/Simple';
import Modal from 'react-bootstrap/Modal';

const PaymentInfo = (props: any) => {
    
    const [alert, setAlert] = useState({
        success: "",
        error: ""
    });

    const [cancelModal, setCancelModal] = useState(false);

    const [loading, setLoading] = useState(false);

    const [subscription, setSubscription] = useState({
        "_id": "",
        "status": "",
        "createdOn": "",
        "updatedOn": "",
        "userId": "",
        "amount": "",
        "currency": "",
        "mail": "",
        "subscription": {
            "id": "",
            "entity": "",
            "plan_id": "",
            "status": "",
            "current_start": "",
            "current_end": "",
            "ended_at": "",
            "quantity": 0,
            "notes": {
                "userId": "",
                "userName": "",
                "plan": "",
                "email": "",
                "amount": "",
                "currency": ""
            },
            "charge_at": "",
            "start_at": "",
            "end_at": '',
            "auth_attempts": 0,
            "total_count": 0,
            "paid_count": 0,
            "customer_notify": true,
            "created_at": 0,
            "expire_by": 0,
            "short_url": "",
            "has_scheduled_changes": false,
            "change_scheduled_at": null,
            "source": "",
            "remaining_count": 0
        },
        "razorpay_order_id": null,
        "razorpay_payment_id": "",
        "razorpay_signature": ""
    });

    useEffect(() => {

        setLoading(true);

        Axios.get(`${keys.url}/payment/subscription/${props.paymentId}`)
            .then(res => {
                setLoading(false)
                setSubscription(res.data);
            })
            .catch(err => {
                console.log(err);
                setLoading(false)
                setAlert({
                    success: "",
                    error: "something went wrong, please try again later"
                })
            })

    }, [props.paymentId]);

    const cancelSubscription = () => {
        setCancelModal(false);
        setLoading(true)
        Axios.post(`${keys.url}/payment/subscription/cancel/${subscription.subscription.id}`)
            .then(res => {
                setLoading(false)
                setAlert({
                    error: "",
                    success: res.data.message
                })
            })
            .catch(err => {
                setLoading(false)
                setAlert({
                    error: "Something went wrong, please try again later",
                    success: ""
                })
            })
    }
    
    return (
        <div>
            <Modal show={props.subModal} onHide={() => props.setSubModal(false)}>
                {loading && <div className="text-center"><div className="spinner-grow text-success" role="status">
                    <span className="sr-only">Loading...</span>
                </div></div>}
                <Alert alert={alert} />
                {subscription ? <>
                    <Modal.Header closeButton>
                        <Modal.Title>Payment Details For {subscription.mail}@code.in</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-lg-6">
                                <h5>Payment ID</h5>
                                <p>{subscription.razorpay_payment_id}</p>
                                <h5>Email</h5>
                                <p>{subscription.mail}@code.in</p>
                                <h5>Plan</h5>
                                <p>{subscription.subscription.notes.plan}</p>
                                <h5>Payment Initiated</h5>
                                <p>{new Date(subscription.createdOn).toLocaleString()}</p>
                            </div>
                            <div className="col-lg-6">
                                <h5>Subscription Status</h5>
                                <p>{subscription.subscription.status === "active"
                                    ?
                                    <span className="text-success">Active</span>
                                    :
                                    <span className="text-danger text-uppercase">{subscription.subscription.status}</span>}
                                </p>
                                {subscription.subscription.status === "active" && <small className="text-info text-uppercase pointer" onClick={()=>setCancelModal(true)}>Cancel Subscription</small>}
                            </div>
                        </div>
                    </Modal.Body>
                </> : <div className="text-center p-5">
                        <div className="spinner-grow text-success" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>}

            </Modal>
            {subscription && <Modal show={cancelModal} onHide={() => setCancelModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Cancel Subscription for {subscription.mail}@code.in</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Are you sure you want cancel subscription for {subscription.mail}@code.in</h5>
                    <p>Please Note you you cannot revert this process and you might lose access to {subscription.mail}@code.in premanently after your subscription expires.</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger" onClick={cancelSubscription}>Cancel Subscription</button>
                </Modal.Footer>
            </Modal>}
        </div>
    )
}

export default PaymentInfo;