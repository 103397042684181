//global imports
import React, { useState, useEffect } from "react";
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import { useHistory } from "react-router-dom";

const Premuim = (props: any) => {
    const history = useHistory();

    const [plans, setPlans] = useState([
        {
            _id: "",
            name: "",
            duration: "",
            paymentType: "",
            price: "",
        }
    ]);

    useEffect(() => {

        setPlans([
            {
                _id: "monthly",
                name: "Monthly",
                duration: "every month",
                paymentType: "Recurring payment of ",
                price: "5000",
            },
        ]);
    }, [])

    const planSelect = (plan: string, premium: Boolean) => {
        if(props.sPremium){
            return
        } else if (premium) {

            history.push(`/buy/${plan}*premium`);

        } else {

            history.push(`/buy/${plan}`);

        }
    };

    return (
        <div className="Process">
            <br />
            <h2 className="text-center">Premium Name!</h2>
            <h3 className="text-center">
                Early bird! <strong>{props.userName}@code.in</strong> is available.
            </h3>
            <br />
            <div>
                <div className="row">
                    {plans.length === 0 ? (
                        <div className="mx-auto">
                            <Spinner animation="grow" />
                        </div>
                    ) : (
                            <>
                                {plans.map((p, i) => {
                                    return (
                                        <div className="mx-auto" key={i} onClick={() => planSelect(p.name, true)}>
                                            {props.sPremium ? <div>
                                                <h5 className="text-center">Please contact meraj@code.in to acquire it.</h5>
                                            </div> :
                                                <Card
                                                    className="text-center pointer mx-auto planSelect"
                                                    style={{ width: "18rem" }}

                                                    key={i}
                                                >
                                                    <Card.Header>
                                                        <h4 className="mb-0">{p.name}</h4>
                                                    </Card.Header>

                                                    <Card.Body>
                                                        <p>{p.paymentType}</p>
                                                        <h3>{p.price}/-</h3>
                                                        <h5 className="mb-0">{p.duration}</h5>
                                                    </Card.Body>

                                                </Card>
                                            }
                                        </div>
                                    );
                                })}
                            </>
                        )}
                </div>
            </div>
            <br />
        </div>
    );
};

export default Premuim;
