import React from "react";
import { BlueLogo } from "../../images";
const Footer = () => {
    return (
        <div className="Footer">
            <p>
                <img
                    style={{ marginTop: "-5px" }}
                    src={BlueLogo} height="15"
                    alt="https://code.in"
                />
                {" "}
                <span
                    style={{ color: "red", 
                    fontSize: "15px" }}
                >♥</span> Trillion
        Ventures.
      </p>
        </div>
    );
};

export default Footer;